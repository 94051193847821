import _ from 'lodash';
import api from '../../utils/service';
import { loadingCycle } from './page';

const GET_ALL_LENDERS_SUCCESS = 'redux/lender/GET_ALL_LENDERS_SUCCESS';
const GET_ALL_LENDERS_FAIL = 'redux/lender/GET_ALL_LENDERS_FAIL';
const DELETE_LENDER_SUCCESS = 'redux/lender/DELETE_LENDER_SUCCESS';
const DELETE_LENDER_FAIL = 'redux/lender/DELETE_LENDER_FAIL';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_LENDERS_SUCCESS:
      return {
        ...state,
        list: action.data,
        error: null,
      };
    case GET_ALL_LENDERS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAllLenders(lenderType) {
  return dispatch =>
    loadingCycle(api.get(`/lenders/?lenderType=${lenderType}`))
      .then(({ data }) => dispatch(getAllLendersSuccess(data)))
      .catch(error => dispatch(getAllLendersFail(error)));
}

export function getAllLendersSuccess(data) {
  return {
    type: GET_ALL_LENDERS_SUCCESS,
    data,
  };
}

export function getAllLendersFail(error) {
  return {
    type: GET_ALL_LENDERS_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}

export function deleteLender(id) {
  return dispatch =>
    loadingCycle(api.delete(`/lenders/${id}`))
      .then(({ data }) => dispatch(deleteLenderSuccess(data)))
      .then(() => dispatch(getAllLenders()))
      .catch(error => dispatch(deleteLenderFail(error)));
}

export function deleteLenderSuccess(data) {
  return {
    type: DELETE_LENDER_SUCCESS,
    data,
  };
}

export function deleteLenderFail(error) {
  return {
    type: DELETE_LENDER_FAIL,
    error: _.get(error, 'response.data.message', _.get(error, 'message')),
  };
}
